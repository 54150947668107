.title {
  margin: 0 0 28px;
  font-size: 32px;
  font-weight: 600;
  line-height: 120%;
  color: #160330;

  &.is-white {
    color: #fff;
  }

  @media (min-width: 768px) {
    margin: 0 0 32px;
    font-size: 56px;
  }
}
