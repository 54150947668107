.page-switcher-container {
  position: relative;
}

.page-switcher-wrapper {
  overflow: auto;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

.page-switcher {
  width: max-content;
  padding: 2px;
  border: 2px solid var(--color);
  border-radius: 25px;
  display: flex;
  align-items: center;

  &.is-violet {
    --color: #823cdc;
  }

  .link {
    padding: 12px 24px;
    border-radius: 21px;
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    color: var(--color);
    white-space: nowrap;
    letter-spacing: .02em;
    text-decoration: none;
    opacity: 1;
    background: transparent;
    transition: background .2s, color .2s, opacity .2s;

    &:hover {
      opacity: .7;
    }

    &.is-active-link {
      color: #000;
      background: var(--color);
      cursor: default;

      &:hover {
        opacity: 1;
        pointer-events: none;
      }
    }

    @media (min-width: 992px) {
      font-size: 18px;
    }
  }

  --color: #fff;
}
