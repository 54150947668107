.is-overflow-hidden {
  overflow: hidden;
}

.child,
.parent {
  display: inline-flex;
  flex-wrap: wrap;
}

.is-block {
  display: block;
}

.child {
  visibility: hidden;

  &.is-visible {
    visibility: visible;

    &.fade-in {
      animation-name: fadeIn;
      animation-duration: .4s;
      animation-fill-mode: forwards;
      animation-timing-function: ease-out;
    }

    &.fade-in-up {
      animation-name: fadeInUp;
      animation-duration: .4s;
      animation-fill-mode: forwards;
      animation-timing-function: ease-out;
    }

    &.fade-in-down {
      animation-name: fadeInDown;
      animation-duration: .4s;
      animation-fill-mode: forwards;
      animation-timing-function: ease-out;
    }

    &.fade-in-left {
      animation-name: fadeInLeft;
      animation-duration: .4s;
      animation-fill-mode: forwards;
      animation-timing-function: ease-out;
    }

    &.fade-in-right {
      animation-name: fadeInRight;
      animation-duration: .4s;
      animation-fill-mode: forwards;
      animation-timing-function: ease-out;
    }

    &.scale-in {
      animation-name: scaleIn;
      animation-duration: .4s;
      animation-fill-mode: forwards;
      animation-timing-function: ease-out;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate(0, 120px);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate(-250px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate(250px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: scale(.5);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}
