.teachers {
  position: relative;
  padding: 48px 0;
  overflow: hidden;

  &.is-b2b {
    .teachers-aside:after {
      inset: 0 -20px -48px;

      @media (min-width: 768px) {
        inset: 0 -100vw 8px 0;
      }

      @media (min-width: 1200px) {
        inset: 0 -100vw 8px 180px;
      }
    }
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
      'title'
      'list'
      'aside';

    @media (min-width: 768px) {
      align-items: center;
      grid-template-columns: 245px 1fr;
      gap: 30px;
      grid-template-areas:
        'title title'
        'list  aside';
    }

    @media (min-width: 1200px) {
      grid-template-columns: 445px 1fr;
      gap: 0 35px;
      grid-template-areas:
        'title aside'
        'list  aside';
    }
  }

  .title-wrap {
    grid-area: title;
  }

  .list-wrap {
    grid-area: list;
  }

  .list {
    position: relative;
    margin: 0 0 72px;
    display: flex;
    flex-direction: column;
    gap: 13px;

    @media (min-width: 768px) {
      margin: 0;
      gap: 32px;
    }

    @media (min-width: 1200px) {
      display: grid;
      justify-content: space-between;
      grid-template-columns: 1fr 1fr;
      gap: 32px 24px;
    }
  }

  .item {
    display: block;
  }

  .item-icon {
    font-size: 24px;
    line-height: 1;

    @media (min-width: 992px) {
      width: 32px;
      height: 32px;
    }
  }

  .item-text {
    margin: 12px 0 0;
    font-size: 14px;
    line-height: 160%;

    @media (min-width: 768px) {
      margin: 16px 0 0;
      font-size: 16px;
    }
  }

  .aside-wrap {
    grid-area: aside;
  }

  .aside {
    position: relative;

    &:after {
      position: absolute;
      z-index: -1;
      inset: 0 -20px 8px;
      content: '';
      background: #f5f7fa;
    }

    @media (min-width: 768px) {
      &:after {
        border-radius: 16px 0 0 16px;
        inset: 0 -100vw 8px 0;
      }
    }

    @media (min-width: 1200px) {
      &:after {
        inset: 0 -100vw 8px 180px;
      }
    }
  }

  .cards-wrapper {
    display: block;
  }

  .cards {
    position: relative;
    padding: 60px 0 0;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    gap: 16px;

    @media (min-width: 768px) {
      width: 412px;
      gap: 20px;
    }

    @media (min-width: 1200px) {
      width: 660px;
      padding: 90px 0 0;
      align-items: center;
    }
  }

  .cards-item {
    width: 156px;
    padding: 16px 18px 8px;
    border-radius: 12px;
    font-size: 14px;
    background: #fff;
    box-shadow: 0 20px 67px rgba(55, 87, 67, .1);

    &:nth-child(2n) {
      margin: -100px 0 0;
    }

    @media (min-width: 768px) {
      width: 186px;
      padding: 20px 18px;
    }

    @media (min-width: 1200px) {
      &:nth-child(2n) {
        margin: 0;
      }

      &:nth-child(3n + 1) {
        transform: translateY(-48px);
      }

      &:nth-child(3n + 3) {
        transform: translateY(-100px);
      }
    }
  }

  .cards-title {
    font-size: 12px;
    font-weight: 600;
    line-height: 120%;

    @media (min-width: 768px) {
      font-size: 16px;
    }
  }

  .cards-img-wrap {
    width: 80px;
    height: 80px;
    padding: 4px;
    margin: 12px auto;
    border: 4px solid #efeaf7;
    border-radius: 50%;
    background: #dac7f2;

    @media (min-width: 768px) {
      width: 99px;
      height: 99px;
    }
  }

  .cards-img {
    max-width: 100%;
    border-radius: 50%;
    display: block;
    object-fit: cover;
    object-position: 100% 50%;
  }

  .cards-text {
    min-height: 45px;
    font-size: 12px;
    line-height: 160%;
    display: flex;
    justify-content: center;

    @media (min-width: 768px) {
      font-size: 14px;
    }
  }

  .circle-container {
    position: absolute;
    top: 10px;
    right: 15px;
    height: 100vh;
    transition: transform .2s;

    @media (min-width: 768px) {
      position: absolute;
      top: -140px;
      right: -280px;
    }

    @media (min-width: 1200px) {
      top: -100px;
      right: -220px;
    }
  }

  @media (min-width: 768px) {
    padding: 90px 0 100px;

    &:after {
      position: absolute;
      top: 175px;
      left: 50%;
      z-index: -1;
      width: 108%;
      height: 540px;
      border-radius: 16px 0 0 16px;
      content: '';
      background: #f5f7fa;
    }
  }

  @media (min-width: 1200px) {
    padding: 140px 0 100px;
  }
}
