.controls {
  color: #823CDC;
  display: flex;

  .button-container {
    display: block;
  }

  .button {
    width: 65px;
    height: 65px;
    padding: 0;
    margin: 0 0 0 8px;
    border: 0;
    outline: 0;
    color: currentcolor;
    display: block;
    opacity: 1;
    cursor: pointer;
    background: transparent;
    transition: opacity .2s;

    &:hover {
      @media (min-width: 992px) {
        opacity: .7;
      }
    }

    & > svg {
      width: 100%;
      height: auto;
      display: block;
      object-fit: contain;
    }

    &.is-disabled {
      opacity: .5;
      cursor: default;

      &:hover {
        @media (min-width: 992px) {
          opacity: .5;
        }
      }
    }
  }

  .button-left {
    transform: rotate(180deg);
  }
}
