.container {
  position: relative;
  z-index: 1;
  padding: 0 16px;

  @media (min-width: 768px) {
    padding: 0 32px;
  }

  @media (min-width: 992px) {
    max-width: 990px;
    padding: 0 15px;
    margin: 0 auto;
  }

  @media (min-width: 1200px) {
    max-width: 1140px;
  }
}
