.trustpilot {
  padding: 48px 0;
  display: block;
  background: #f5f7fa;

  &-grid {
    display: grid;
    justify-items: center;
    grid-template-columns: 1fr;
    grid-template-areas:
      "title"
      "logo"
      "stars"
      "link";

    @media (min-width: 768px) {
      justify-items: start;
      grid-template-areas:
        "title title"
        "stars logo"
        "link logo";
    }

    @media (min-width: 1200px) {
      gap: 11px 49px;
      grid-template-areas:
        "title stars logo"
        "title link logo";
    }
  }

  &-title {
    margin: 0 0 24px;
    font-size: 24px;
    font-weight: 600;
    line-height: 120%;
    color: #160330;
    flex: 1;
    grid-area: title;

    @media (min-width: 768px) {
      margin: 0 0 50px;
      font-size: 32px;
    }

    @media (min-width: 1200px) {
      margin: 0;
      font-size: 32px;
      text-align: left;
    }
  }

  &-img {
    max-width: 100%;
    display: block;
  }

  &-stars {
    width: 258px;
    margin: 0 0 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-area: stars;

    @media (min-width: 768px) {
      width: 300px;
    }

    @media (min-width: 1200px) {
      margin: 0;
    }
  }

  &-logo {
    width: 258px;
    margin: 0 0 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-area: logo;

    @media (min-width: 768px) {
      width: 308px;
      margin: 0;
    }

    @media (min-width: 1200px) {
      margin: 0;
    }
  }

  &-link {
    margin: 0 53px 0 0;
    font-size: 24px;
    font-weight: 700;
    line-height: 120%;
    color: #00b67a;
    text-align: center;
    grid-area: link;
    text-decoration-line: underline;

    @media (min-width: 768px) {
      margin: 0;
      font-size: 24px;
    }
  }

  @keyframes ticker {
    0% {
      visibility: visible;
      transform: translate3d(0, 0, 0);
    }

    100% {
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes swap {
    0%, 50% {
      left: 0%;
    }

    50.001%, 100% {
      left: 100%;
    }
  }

  &-partners-wrap {
    overflow: hidden;
  }

  &-partners {
    position: relative;
    padding: 0 16px;
    margin: 24px 0 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;

    @media (min-width: 768px) {
      padding: 0 32px;
      margin: 50px auto 0;
      gap: 64px;
    }

    @media (min-width: 992px) {
      max-width: 990px;
      padding: 0 15px;
    }

    @media (min-width: 1200px) {
      width: max-content;
      max-width: max-content;
      padding: 0;
      flex-wrap: nowrap;
      gap: 0;
      animation: ticker 30s linear infinite;
    }
  }

  &-partners-group {
    position: relative;
    width: max-content;
    max-width: max-content;
    display: flex;
    flex-wrap: nowrap;

    &:first-child {
      animation: swap 30s linear infinite;
    }
  }

  &-partner {
    width: auto;
    height: 40px;
    opacity: 0.4;
    transition: opacity .2s ease, filter .2s ease;
    filter: grayscale(1);

    &:hover {
      opacity: 1;
      filter: grayscale(0);
    }

    @media (min-width: 768px) {
      height: 50px;
    }

    @media (min-width: 1200px) {
      height: 58px;
      margin: 0 20px;
    }
  }

  @media (min-width: 768px) {
    padding: 120px 0;
  }
}
