.benefits {
  padding: 48px 0;
  color: #160330;
  background: #fff;

  &.is-gray {
    background: #f5f5f5;
  }

  &.is-horizontal {
    @media (min-width: 1200px) {
      .list {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
      }

      .list > :last-child .item {
        border-right: 0;
      }

      .item {
        padding: 0 16px;
        border-bottom: 0;
        align-items: flex-start;
        flex-direction: column;
        border-right: 1px solid rgba(#111, .2);
      }

      .count {
        min-width: auto;
        margin: 0 0 8px;
        font-size: 72px;
      }

      .text {
        font-size: 18px;
      }
    }
  }

  .list {
    margin: -28px 0 0;

    @media (min-width: 992px) {
      margin: 0;
    }
  }

  .item {
    width: 100%;
    padding: 28px 0;
    border-bottom: 1px solid #282c34;

    @media (min-width: 768px) {
      padding: 40px 0;
    }

    @media (min-width: 992px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .count {
    min-width: 100px;
    margin: 0 0 16px;
    font-size: 46px;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: .02em;

    &.is-green {
      color: #70d65c;
    }

    &.is-blue {
      color: #3da2f5;
    }

    &.is-purple {
      color: #823cdc;
    }

    &.is-yellow {
      color: #fdb548;
    }

    @media (min-width: 768px) {
      min-width: 330px;
      margin: 0 0 28px;
      font-size: 88px;
    }

    @media (min-width: 992px) {
      min-width: 520px;
      margin: 0 60px 0 0;
      font-size: 132px;
    }
  }

  .text {
    font-size: 24px;
    font-weight: 600;
    line-height: 160%;
    flex-grow: 1;

    @media (min-width: 768px) {
      font-size: 32px;
    }
  }

  @media (min-width: 768px) {
    padding: 120px 0;
  }
}
