.slider {
  position: relative;
  z-index: 3;
  width: 100%;
  height: 100%;

  .inner {
    width: fit-content;
    height: 100%;
    display: flex;
    transition: transform .4s;
  }
}
