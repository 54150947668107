@keyframes promoFadeUp {
  from {
    transform: translateY(120px);
    opacity: 0;
  }

  to {
    transform: translate(0);
    opacity: 1;
  }
}

.promo {
  padding: 83px 0 48px;
  background: #823cdc;

  &.is-b2b {
    background: url(./assets/promo-b2b-background--mobile.jpg) 0% 50%/cover;

    .list {
      @media (min-width: 768px) {
        flex-direction: column;
      }
    }

    .row {
      @media (min-width: 768px) {
        gap: 32px;
      }

      @media (min-width: 1200px) {
        gap: 24px 15px;
      }
    }

    @media (min-width: 768px) {
      background: url(./assets/promo-b2b-background--tablet.jpg) 0% 50%/cover;
    }

    @media (min-width: 1200px) {
      background: url(./assets/promo-b2b-background.jpg) 0% 50%/cover;
    }
  }

  .row {
    display: flex;
    flex-direction: column;
    gap: 40px 65px;

    @media (min-width: 768px) {
      gap: 24px 65px;
    }

    @media (min-width: 1200px) {
      flex-direction: row;
    }
  }

  .content {
    text-align: center;
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: column;

    @media (min-width: 1200px) {
      text-align: left;
      align-items: flex-start;
    }
  }

  .page-switcher-wrap {
    width: fit-content;
  }

  .title {
    margin: 24px 0;
    font-size: 32px;
    font-weight: 700;
    line-height: 140%;
    color: #fff;
    animation: promoFadeUp .5s ease-in-out;
    animation-fill-mode: backwards;

    & > i {
      position: relative;
      font-style: normal;
      display: inline-block;

      &:after {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        height: 11px;
        border-radius: 6px;
        display: block;
        content: ' ';
        background: #ffb466;
        transform: translate(0, -5px);

        @media (min-width: 768px) {
          transform: translate(0, -12px);
        }

        @media (min-width: 1200px) {
          height: 18px;
          border-radius: 10px;
          transform: translate(0, -6px);
        }
      }
    }

    @media (min-width: 768px) {
      font-size: 56px;
    }

    @media (min-width: 1200px) {
      font-size: 56px;
    }
  }

  .list {
    margin: 0 0 32px;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 12px 32px;
    animation: promoFadeUp .5s ease-in-out;
    animation-delay: .1s;
    animation-fill-mode: backwards;

    @media (min-width: 768px) {
      max-width: 560px;
      margin: 8px auto 32px;
      flex-direction: row;
    }

    @media (min-width: 1200px) {
      margin: 8px 0 32px;
      flex-direction: column;
      gap: 16px;
    }
  }

  .item {
    position: relative;
    padding: 0 0 0 14px;
    font-size: 16px;
    font-weight: 600;
    line-height: 140%;
    color: #fff;
    display: block;
    flex: 1;

    &:after {
      position: absolute;
      top: 9px;
      left: 0;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      content: '';
      background: currentcolor;
    }

    @media (min-width: 768px) {
      font-size: 18px;
    }

    @media (min-width: 1200px) {
      font-size: 18px;
    }
  }

  .button-wrapper {
    width: fit-content;
    display: block;
    animation: promoFadeUp .5s ease-in-out;
    animation-delay: .5s;
    animation-fill-mode: backwards;
  }

  .aside {
    position: relative;
    width: 328px;
    padding: 0 0 16px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: 768px) {
      width: 570px;
      padding: 0;
    }

    @media (min-width: 1200px) {
      width: 570px;
      margin: 0;
    }
  }

  .aside-img-wrap {
    width: fit-content;
  }

  @keyframes promoImgAnimation {
    from {
      transform: scale(.7);
      opacity: 0;
    }

    to {
      transform: scale(1);
      opacity: 1;
    }
  }

  .aside-img {
    width: 328px;
    border-radius: 16px;
    display: block;
    animation: promoImgAnimation .4s ease-in-out;
    animation-delay: 1s;
    animation-fill-mode: backwards;

    @media (min-width: 768px) {
      width: 510px;
    }
  }

  @keyframes promoDescrAnimation {
    from {
      transform: translate(-100px, -60px) scale(.7);
      opacity: 0;
    }

    to {
      transform: translate(0, 0) scale(1);
      opacity: 1;
    }
  }

  .aside-descr {
    position: absolute;
    left: 8px;
    bottom: 64px;
    width: 180px;
    padding: 13px 20px;
    border-radius: 16px;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background: #3da2f5;
    gap: 10px;
    animation: promoDescrAnimation .4s ease-in-out;
    animation-delay: 1.5s;
    animation-fill-mode: backwards;

    @media (min-width: 768px) {
      left: 0;
      bottom: 114px;
      width: 186px;
      padding: 38px 20px;
    }
  }

  .trustpilot {
    position: absolute;
    left: -16px;
    bottom: 0;
    width: 220px;
    height: 97px;
    background: url(./assets/trustpilot.svg) 0/contain no-repeat;
    animation: promoDescrAnimation .4s 1.5s ease-in-out backwards;

    @media (min-width: 768px) {
      left: 0;
      bottom: -16px;
    }
  }

  .aside-descr-icons {
    font-size: 18px;
    line-height: 1;
  }

  .aside-descr-text {
    font-size: 14px;
    font-weight: 600;
    line-height: 120%;
    color: #fff;
    display: block;
  }

  @keyframes promoPlatformAnimation {
    from {
      transform: translate(-100px, 70px) scale(.7);
      opacity: 0;
    }

    to {
      transform: translate(0, 0) scale(1);
      opacity: 1;
    }
  }

  .aside-platform {
    position: absolute;
    left: 8px;
    bottom: 4px;
    padding: 15px 12px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background: #ffb466;
    animation: promoPlatformAnimation .4s ease-in-out;
    animation-delay: 2s;
    animation-fill-mode: backwards;

    @media (min-width: 768px) {
      left: 0;
      bottom: 30px;
      padding: 22px 25px;
    }
  }

  .aside-platform-text {
    font-size: 14px;
    font-weight: 600;
    line-height: 120%;
    color: #fff;
    display: block;
  }

  .aside-platform-icons {
    font-size: 18px;
    line-height: 1;
  }

  @keyframes promoTeacherAnimation {
    from {
      transform: translate(100px, -60px) scale(.7);
      opacity: 0;
    }

    to {
      transform: translate(0, 0) scale(1);
      opacity: 1;
    }
  }

  .aside-teacher {
    position: absolute;
    right: 8px;
    bottom: 0;
    width: 120px;
    padding: 16px 11px;
    border-radius: 16px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background: #fff;
    gap: 8px;
    animation: promoTeacherAnimation .4s ease-in-out;
    animation-delay: 2.5s;
    animation-fill-mode: backwards;

    @media (min-width: 768px) {
      right: 0;
      bottom: 30px;
      width: 186px;
      padding: 24px 40px;
    }
  }

  .aside-teacher-text {
    font-size: 14px;
    font-weight: 600;
    line-height: 120%;
    color: #111;
  }

  .aside-teacher-icons {
    font-size: 18px;
    line-height: 1;
  }

  .aside-teacher-photo-wrap {
    padding: 4px;
    margin: 2px auto 0;
    border: 4px solid #efeaf7;
    border-radius: 50%;
    background: #dac7f2;
  }

  .aside-teacher-photo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: block;

    @media (min-width: 768px) {
      width: 83px;
      height: 83px;
    }
  }

  .aside-teacher-buttons {
    display: flex;
    gap: 8px;
  }

  .aside-teacher-button {
    width: 24px;
    height: 24px;
    display: block;

    @media (min-width: 768px) {
      width: 32px;
      height: 32px;
    }
  }

  @media (min-width: 768px) {
    padding: 90px 0 48px;
  }

  @media (min-width: 1200px) {
    padding: 140px 0 100px;
  }
}
