.faq {
  padding: 48px 0;

  .questions {
    margin: 0 0 20px;
    display: flex;
    flex-direction: column;

    @media (min-width: 1200px) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .questions-column {
    display: flex;
    flex: auto;
    flex-direction: column;

    @media (min-width: 1200px) {
      flex: 0 0 calc(100% / 2 - 15px);
    }
  }

  .question {
    padding: 18px 20px 24px;
    margin: 0 0 20px;
    border-radius: 16px;
    background: #fff;
    box-shadow: 0 5px 16px rgba(8, 15, 52, .06);
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;

    &.is-open {
      .button > .arrow {
        display: block;
        transition: all .2s ease-in;
        transform: rotate(90deg);
        background: url(./assets/arrow--white.svg) no-repeat center center / contain;
      }

      .answer {
        max-height: 1000px;
        margin: 16px 0 0;
        visibility: visible;
        opacity: 1;
        transition: margin .2s, opacity .2s, max-height .2s;
      }
    }

    .question-text {
      width: 100%;
      padding: 0;
      font-size: 14px;
      font-weight: 700;
      line-height: 1.4;
      color: #111;
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: none;

      @media (min-width: 768px) {
        font-size: 16px;
        line-height: 1.5;
      }
    }

    .button {
      width: 35px;
      height: 35px;
      margin: 0 0 0 10px;
      display: block;
      flex-shrink: 0;
      -webkit-tap-highlight-color: transparent;

      @media (min-width: 768px) {
        width: 45px;
        height: 45px;
      }
    }

    .button > .arrow {
      width: 35px;
      height: 35px;
      display: block;
      transition: all .2s ease-in;
      transform: rotate(0deg);
      background: url(./assets/arrow.svg) no-repeat center center / contain;

      @media (min-width: 768px) {
        width: 45px;
        height: 45px;
      }
    }

    .answer {
      max-height: 0;
      margin: 0;
      font-size: 14px;
      line-height: 1.6;
      color: rgba(var(--c-text-rgb), .7);
      visibility: hidden;
      transition: margin .2s, opacity .2s, max-height .2s;
      opacity: 0;

      @media (min-width: 768px) {
        font-size: 16px;
      }
    }

    @media (min-width: 768px) {
      padding: 36px;
    }
  }

  @media (min-width: 768px) {
    padding: 120px 0 100px;
  }
}
