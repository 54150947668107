.slider-line {
  padding: 48px 0;
  color: #282c34;
  overflow: hidden;
  background: #f5f7fa;

  &.is-purple {
    background: #823cdc;

    .subtitle {
      color: #fff;
    }

    .button {
      color: #fff;
    }
  }

  .header {
    @media (min-width: 768px) {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
    }
  }

  .title-wrap {
    flex: 1;
  }

  .subtitle {
    margin: 0 0 28px;
    font-size: 18px;
    font-weight: 600;
    line-height: 120%;
    color: #282c34;
    display: block;

    @media (min-width: 768px) {
      margin: 0 0 32px;
      font-size: 32px;
    }
  }

  .buttons-wrapper {
    margin: 0 0 28px;
    display: flex;

    @media (min-width: 768px) {
      margin: 0 0 32px 20px;
    }
  }

  .button {
    -webkit-tap-highlight-color: transparent;
  }

  .list {
    width: 100%;
  }

  .item {
    width: 312px;
    padding: 16px;
    margin: 0 16px 0 0;
    border-radius: 16px;
    flex-shrink: 0;
    background: #fff;

    &.is-small {
      width: 255px;
    }

    @media (min-width: 768px) {
      padding: 32px;
      margin: 0 24px 0 0;
    }
  }

  .icon {
    margin: 0 0 16px;
    font-size: 24px;
    line-height: 1;
    display: block;

    @media (min-width: 768px) {
      margin: 0 0 24px;
      font-size: 32px;
    }
  }

  .title {
    margin: 0 0 16px;
    font-size: 18px;
    font-weight: 700;
    line-height: 130%;

    @media (min-width: 768px) {
      margin: 0 0 24px;
    }
  }

  .text {
    font-size: 14px;
    line-height: 160%;

    @media (min-width: 768px) {
      font-size: 16px;
    }
  }

  .button-wrap {
    margin: 26px 0 0;
    display: flex;

    @media (min-width: 768px) {
      display: block;
    }
  }

  @media (min-width: 768px) {
    padding: 120px 0;
  }
}
