.circle {
  position: relative;

  .container {
    position: absolute;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }

  .img {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
}
