.footer {
  padding: 40px 0;
  background: #f5f7fa;

  .row {
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 24px;

    @media (min-width: 768px) {
      text-align: left;
      align-items: flex-start;
      justify-content: space-between;
      gap: 44px;
    }

    @media (min-width: 992px) {
      flex-direction: row;
    }

    @media (min-width: 1200px) {
      gap: 120px;
    }
  }

  .col {
    display: block;
  }

  .title {
    margin: 0 0 16px;
    font-size: 14px;
    font-weight: 600;
    color: #959698;
    display: block;
    letter-spacing: 0.7px;
    text-transform: uppercase;
  }

  .phone {
    margin: 0 0 4px;
    font-size: 16px;
    font-weight: 600;
    line-height: 130%;
    color: #111;
    display: block;
    text-decoration: none;
  }

  .time {
    font-size: 12px;
    color: #838486;
    display: block;
  }

  .emails {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media (min-width: 768px) {
      flex-direction: row;
      gap: 24px;
    }
  }

  .email-wrap {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .label {
    font-size: 12px;
    color: #838486;
  }

  .email {
    font-size: 16px;
    line-height: 150%;
    color: #111;
    text-decoration: none;
  }

  .links {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .link {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.4;
    color: #111;
    text-decoration: none;
  }

  .logo {
    width: auto;
    height: 45px;
    margin: 0 0 16px;
    display: block;
  }

  .info-and-address {
    @media (min-width: 768px) {
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 20px;
    }
  }

  .address {
    max-width: 214px;
    margin: 0 0 8px;
    font-size: 12px;
    color: #838486;
    display: block;
  }

  .schedule-and-socials {
    margin: 0 0 32px;

    @media (min-width: 768px) {
      margin: 0 0 40px;
      display: flex;
      align-items: flex-end;
      gap: 17px;
    }
  }

  .social {
    margin: 32px 0 0;
    display: flex;
    justify-content: center;
    gap: 16px;

    @media (min-width: 768px) {
      margin: 0;
      justify-content: flex-end;
    }
  }

  .social-link {
    width: 32px;
    height: 32px;
    border-radius: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    background: #c7c9cb;
  }

  .social-icon {
    display: block;
  }
}
